<template>
  <b-sidebar
    id="add-new-menu-sidebar"
    :visible="isAddNewMenuSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-menu
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-menu-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Menu -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-menu px-2 py-1">
        <h5 class="mb-0">
          {{$t('tabs.addnewmenu')}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >

          <!-- title -->
          <validation-provider
            #default="validationContext"
            rules="required"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <template #label>{{$t('labels.title')}}</template>
              <b-form-input
                id="title"
                v-model="menuData.title"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Users -->
          <b-form-group>
            <b-form-checkbox
              name="check-button"
              v-model="menuData.is_desplegable"
              switch
              inline
            >
              {{$t('labels.dropdown')}}
            </b-form-checkbox>
          </b-form-group>

          <!-- background color -->
          <validation-provider
            #default="validationContext"
            name="BackgroundColor"
            rules="required"
          >
            <b-form-group
              label="Background color"
              label-for="backgroundcolor"
            >
              <template #label>{{$t('labels.backgroundcolor')}}</template>
              <b-form-input
              type="color"
                id="backgroundcolor"
                v-model="menuData.background_color"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <!-- background image -->
          <validation-provider
            #default="validationContext"
            name="BackgroundImage"
            rules="required|url"
          >
            <b-form-group
              label="Background image"
              label-for="backgrounimage"
            >
              <template #label>{{$t('labels.backgroundimage')}}</template>
              <b-form-file
                id="backgroundimage"
                v-model="menuData.background_image"
                :state="Boolean(menuData.background_image) || getValidationState(validationContext)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{$t('buttons.add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{$t('buttons.cancel')}}
            </b-button>
          </div>

        <div>
          <br>
          <br>
          <span>*** {{ $t('menu_add_text') }}</span>
        </div>
          
        </b-form>
      </validation-observer>
      
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    vSelect,
    BFormFile,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewMenuSidebarActive',
    event: 'update:is-add-new-menu-sidebar-active',
  },
  props: {
    isAddNewMenuSidebarActive: {
      type: Boolean,
      required: true,
    },
    
  },
  data() {
    return {
      required,
      alphaNum,
      optionsHide: [
        { value: true, text: 'True' },
        { value: false, text: 'False' }
      ],
    }
  },
  setup(props, { emit }) {
        
    const blankMenuData = {
      title: '',
      background_color: '',
      background_image: null,
      is_desplegable: false,     
    }

    const menuData = ref(blankMenuData)
    const resetmenuData = () => {
      menuData.value = blankMenuData
    }

    const onSubmit = async () => {
      const form = new FormData()
      const data = menuData.value
      console.log(data)
      form.append('title', data.title)
      form.append('background_color', data.background_color)
      form.append('background_image', data.background_image)
      form.append('is_desplegable', data.is_desplegable)
      try{
        await store.dispatch('app-menu/addMenu', form)          
        emit('refetch-data')
        emit('update:is-add-new-menu-sidebar-active', false)
      }catch(error) {
        emit('update:is-add-new-menu-sidebar-active', false)
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmenuData)

    return {
      menuData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-menu-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>