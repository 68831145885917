<template>
  <b-sidebar
    id="add-link-menu-sidebar"
    :visible="isAddLinkMenuSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-menu
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-link-menu-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Menu -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-menu px-2 py-1">
        <h5 class="mb-0">
          {{ $t('labels.links') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('buttons.add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
          </div>

        <div>
          <br>
          <br>
          <span>*** {{ $t('menu_add_text') }}</span>
        </div>
          
        </b-form>
      </validation-observer>
      
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox,
  BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    vSelect,
    BFormCheckbox,
    BCol,
    BRow,
  
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddLinkMenuSidebarActive',
    event: 'update:is-add-link-menu-sidebar-active',
  },
  props: {
    isAddLinkMenuSidebarActive: {
      type: Boolean,
      required: true,
    },
    menuData : Object, 
    
  },
  data() {
    return {
      required,
      alphaNum,
      optionsHide: [
        { value: true, text: 'True' },
        { value: false, text: 'False' }
      ],
    }
  },
  setup(props, { emit }) {
        
    const blankMenuData = {
      title: '',
      background_color: '',
      background_image: '',
      is_desplegable: false,
      links: [],
     
    }

    const linkData = ref(JSON.parse(JSON.stringify(blankMenuData)))
    const resetlinkData = () => {
      linkData.value = JSON.parse(JSON.stringify(blankMenuData))
    }

    const onSubmit = () => {
      console.log('mandando')
      // store.dispatch('app-menu/addMenu', linkData.value)
      //   .then( () => {
      //     emit('refetch-data')
      //     emit('update:is-add-link-menu-sidebar-active', false)
      //   }).catch(() => {
      //      emit('update:is-add-link-menu-sidebar-active', false)
      //   })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetlinkData)

    return {
      linkData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods:
  {
    removeItem(index) {
      console.log(this.menuData)
      // console.log(index)
      this.menuData.links.splice(index, 1)
      //.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    pushItem()
    {
      this.menuData.links.push([])
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-link-menu-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>