<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{$t('labels.show')}}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{$t('labels.entries')}}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <div>
                <b-button
                  variant="primary"
                  :to="{ name: 'apps-menus-store'}"
                >
                  <span class="text-nowrap">{{$t('buttons.add')}}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-overlay :show="loading"
                 :variant="$store.state.appConfig.layout.skin"
                 blur="2"
                 class="p-50">
      <b-table
        ref="refMenuListTable"
        class="position-relative"
        :items="fetchMenu"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text= "this.$t('messages.nomatching')"
        :sort-desc.sync="isSortDirDesc"
        style="min-height: 60vh"
      >
        <template #cell(background)="data">
          <color-table-field :color="data.item.background_color">
            <image-table-field :url="data.item.background_image"></image-table-field>
          </color-table-field>
        </template>
        
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- <b-button variant="primary" class="btn-icon rounded-circle mr-1"
            v-b-tooltip.hover.top="$t('tooltips.detail')"
            :to="{ name: 'apps-users-view', params: { id: data.item._id } }" size="sm">
            <feather-icon icon="FileTextIcon" />
          </b-button> -->

          <b-button variant="primary" class="btn-icon rounded-circle mr-1"
            v-b-tooltip.hover.top="$t('tooltips.preview')" @click="preview(data.item)" size="sm">
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button variant="primary" class="btn-icon rounded-circle mr-1"
            v-b-tooltip.hover.top="$t('tooltips.edit')" size="sm"
            :to="{ name: 'apps-menus-store', params: { id: data.item._id } }"
            >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button variant="primary" class="btn-icon rounded-circle mr-1"
            v-b-tooltip.hover.top="$t('tooltips.delete')" @click="removeMenu(data.item._id)" size="sm">
            <feather-icon icon="TrashIcon" />
          </b-button>          
        </template>

      </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{$t('labels.showing')}} {{ dataMeta.from }} {{$t('labels.to')}} {{ dataMeta.to }} {{$t('labels.of')}} {{ dataMeta.of }} {{$t('labels.entries')}}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMenu"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <previewModal ref="previewModal" />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useMenuList from './useMenusList'
import menuStoreModule from '../menuStoreModule'
import MenuListAddNew from './menuListAddNew.vue'
import AddLinkMenu from './addLinkMenu.vue'
import ImageTableField from '@/views/components/ImageTableField'
import ColorTableField from '@/views/components/ColorTableField'
import previewModal from './previewModal.vue'

export default {
  components: {
    previewModal,
   
    MenuListAddNew,
    AddLinkMenu,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBTooltip,
    ImageTableField,
    ColorTableField,
    vSelect,
  },
   directives: {
    'b-tooltip': VBTooltip
  },
  data(){
    return{
      nomatching: this.$t('messages.nomatching')
    }
  },

  computed: {
    tableColumns() {
      return [
        {key: 'name', sortable: false, label: this.$t('labels.name')},
        {key: 'background', sortable: false, label: this.$t('labels.background')},
        {key: 'actions', label: this.$t('labels.actions')},
      ]
    }
  },
  setup() {
    const HOMEPAGE_APP_STORE_MODULE_NAME = 'app-menu'

    if (!store.hasModule(HOMEPAGE_APP_STORE_MODULE_NAME)) store.registerModule(HOMEPAGE_APP_STORE_MODULE_NAME, menuStoreModule)
    onUnmounted(() => {
      if (store.hasModule(HOMEPAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(HOMEPAGE_APP_STORE_MODULE_NAME)
    })

    const isAddNewMenuSidebarActive = ref(false)
    const isAddLinkMenuSidebarActive = ref(false)
  

    const {
      fetchMenu,
      perPage,
      currentPage,
      totalMenu,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMenuListTable,
      refetchData,
      recordDeleted,
      menuData,
      loading,
      // UI
           
      resolveMenuStatusVariant,
    } = useMenuList()

    return {
      // Sidebar
      isAddNewMenuSidebarActive,
      isAddLinkMenuSidebarActive,
      fetchMenu,
      perPage,
      currentPage,
      totalMenu,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMenuListTable,
      refetchData,
      recordDeleted,
      menuData,
      loading,
      // UI
      resolveMenuStatusVariant,

    }
  },
  methods: {
    linkSidebar(item)
    {
      this.menuData = item
      this.isAddLinkMenuSidebarActive = true
    },
    removeMenu(Id) {
      store.dispatch('app-menu/removeMenu', { id: Id })
        .then(() => {
          this.recordDeleted = !this.recordDeleted
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Menu removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
    preview(item) {
      this.$refs.previewModal.show(item)      
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>