<template>
  <div>
    <b-modal
      centered
      :title="$t('tooltips.preview')"
      ok-only
      v-model="showPreview"
      size="xl"
      hide-footer
    >
    <preview-nav :menu="menu"></preview-nav>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, VBModal, BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PreviewNav from '@/views/components/templates/menu/nav.vue'
export default {
  components: {
    BButton,
    BModal,
    BAlert,
    PreviewNav
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data: () => ({
    showPreview: false,
    menu: {},
  }),
    methods: {
        show(item){
            this.showPreview = true
            this.menu = item
        }
    },
}
</script>